<template>
  <div class="manage-booking-detail">
    <div v-if="isLoaded" class="container fluid">
      <h3 class="mb-4 px-2">Booking Detail</h3>
      <!-- ========================== Booking Info ========================= -->
      <div class="my-3 px-1">
        <div class="bordered-card">
          <booking-info
            :booking="salesPurchase.projectPurchaseBooking"
            :downloadFileAPI="downloadFileAPI"
            :saleId="salesPurchase.id"
          ></booking-info>
          <booking-actions
            class="p-3"
            v-bind="{
              listingRouteName,
              getBookingDetail,
              canManage,
              sales: salesPurchase,
              approveBookingAPI,
              rejectBookingAPI,
              cancelBookingAPI
            }"
          >
          </booking-actions>
        </div>
      </div>
      <!-- ========================== Agent Info =========================== -->
      <agent-info
        v-if="salesPurchase.agent"
        class="bordered-container p-3 mb-3 mx-1"
        :agencyUser="salesPurchase.agent"
      ></agent-info>

      <!-- ======================= Project Unit Info ======================= -->
      <div class="bordered-container project-unit-info p-3 mb-2 mx-1">
        <h3 class="mb-4">Project Unit</h3>

        <div class="mb-2">
          <p class="info--title">{{ salesPurchase.project.name }}</p>
        </div>

        <div class="row">
          <display-data
            :class="projectInfoDataCSS"
            label="Unit Type"
            :content="salesPurchase.projectUnitType.name"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Phase"
            :content="salesPurchase.projectPurchaseReservation.projectPhase"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Property Type"
            :content="salesPurchase.projectPurchaseReservation.propertyType"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Property Category"
            :content="salesPurchase.project.propertyCategory"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Parcel No / Unit No"
            :content="salesPurchase.projectPurchaseReservation.unitPlot"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Build Up Area"
            :content="`${parseInt(
              salesPurchase.projectPurchaseReservation.buildUpArea
            )} ${salesPurchase.projectUnitFloorPlan.areaUnitType}`"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Land Area"
            :content="`${parseInt(
              salesPurchase.projectPurchaseReservation.landArea
            )} ${salesPurchase.projectUnitFloorPlan.areaUnitType}`"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="SPA Price (Sale Price)"
            :content="`RM ${parseInt(
              salesPurchase.projectPurchaseReservation
                .salesPurchaseAgreementPrice
            )}`"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Bedroom"
            :content="`${salesPurchase.projectUnitFloorPlan.numberOfBedroom} + ${salesPurchase.projectUnitFloorPlan.extraBedroom}`"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Bathroom"
            :content="`${salesPurchase.projectUnitFloorPlan.numberOfBathroom} + ${salesPurchase.projectUnitFloorPlan.extraBathroom}`"
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Tenure"
            :content="
              salesPurchase.projectUnit.tenureType.length > 0
                ? salesPurchase.projectUnit.tenureType.join(', ')
                : 'No Tenure Type'
            "
          ></display-data>
          <display-data
            :class="projectInfoDataCSS"
            label="Lot Type"
            :content="
              $isStringEmpty(salesPurchase.projectUnit.lotType)
                ? 'No Lot Type'
                : salesPurchase.projectUnit.lotType.name
            "
          ></display-data>
          <display-data label="Address" :class="projectInfoDataCSS">
            <span v-if="salesPurchase.project.building">
              {{ $toTitleCase(salesPurchase.project.building.name) }},
            </span>
            {{ $toTitleCase(salesPurchase.projectUnit.street) }},
            {{ $toTitleCase(salesPurchase.project.area.name) }},
            {{ $toTitleCase(salesPurchase.project.city.name) }},
            {{ $toTitleCase(salesPurchase.project.state.name) }}
          </display-data>
        </div>
      </div>

      <!-- ======================== Reservation Info ======================= -->
      <reservation-info
        class="bordered-container p-3 mb-2 mx-1"
        :projectPurchase="salesPurchase"
      ></reservation-info>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    ReservationInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationInfo"
      ),
    BookingActions: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingDetailActions"
      ),
    BookingInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingInfo"
      )
  },
  mixins: [],
  props: {
    listingRouteName: {
      type: String,
      default: "ManageProjectBooking"
    },
    canManage: {
      type: Boolean,
      default: true
    },
    getBookingDetailAPI: {
      type: Function,
      required: true
    },
    downloadFileAPI: {
      type: Function,
      required: true
    },
    approveBookingAPI: {
      type: Function,
      required: true
    },
    rejectBookingAPI: {
      type: Function,
      required: true
    },
    cancelBookingAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      isLoaded: false,
      projectInfoDataCSS: "col-12 sm-col-6 md-col-4"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await this.getBookingDetail();
      this.isLoaded = true;
      this.$store.commit("setIsLoading", false);
    },

    async getBookingDetail() {
      try {
        let data = await this.getBookingDetailAPI(this.$route.params.id);
        this.salesPurchase = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get the booking detail. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-booking-detail {
  .buyer-name {
    font-weight: bold;
    font-size: 20px;
  }

  .project-unit-info {
    .info--title {
      font-weight: bold;
      font-size: 20px;
    }
    .info--sub-title {
      font-size: 14px;
      color: #6a6a6a;
    }
  }

  .signature {
    width: 100px;
    max-width: 100%;
    height: 100px;
  }

  .bordered-container {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 16px;
  }
}
</style>
