var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-booking-detail"},[(_vm.isLoaded)?_c('div',{staticClass:"container fluid"},[_c('h3',{staticClass:"mb-4 px-2"},[_vm._v("Booking Detail")]),_c('div',{staticClass:"my-3 px-1"},[_c('div',{staticClass:"bordered-card"},[_c('booking-info',{attrs:{"booking":_vm.salesPurchase.projectPurchaseBooking,"downloadFileAPI":_vm.downloadFileAPI,"saleId":_vm.salesPurchase.id}}),_c('booking-actions',_vm._b({staticClass:"p-3"},'booking-actions',{
            listingRouteName: _vm.listingRouteName,
            getBookingDetail: _vm.getBookingDetail,
            canManage: _vm.canManage,
            sales: _vm.salesPurchase,
            approveBookingAPI: _vm.approveBookingAPI,
            rejectBookingAPI: _vm.rejectBookingAPI,
            cancelBookingAPI: _vm.cancelBookingAPI
          },false))],1)]),(_vm.salesPurchase.agent)?_c('agent-info',{staticClass:"bordered-container p-3 mb-3 mx-1",attrs:{"agencyUser":_vm.salesPurchase.agent}}):_vm._e(),_c('div',{staticClass:"bordered-container project-unit-info p-3 mb-2 mx-1"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Project Unit")]),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"info--title"},[_vm._v(_vm._s(_vm.salesPurchase.project.name))])]),_c('div',{staticClass:"row"},[_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Unit Type","content":_vm.salesPurchase.projectUnitType.name}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Phase","content":_vm.salesPurchase.projectPurchaseReservation.projectPhase}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Property Type","content":_vm.salesPurchase.projectPurchaseReservation.propertyType}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Property Category","content":_vm.salesPurchase.project.propertyCategory}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Parcel No / Unit No","content":_vm.salesPurchase.projectPurchaseReservation.unitPlot}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Build Up Area","content":((parseInt(
            _vm.salesPurchase.projectPurchaseReservation.buildUpArea
          )) + " " + (_vm.salesPurchase.projectUnitFloorPlan.areaUnitType))}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Land Area","content":((parseInt(
            _vm.salesPurchase.projectPurchaseReservation.landArea
          )) + " " + (_vm.salesPurchase.projectUnitFloorPlan.areaUnitType))}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"SPA Price (Sale Price)","content":("RM " + (parseInt(
            _vm.salesPurchase.projectPurchaseReservation
              .salesPurchaseAgreementPrice
          )))}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Bedroom","content":((_vm.salesPurchase.projectUnitFloorPlan.numberOfBedroom) + " + " + (_vm.salesPurchase.projectUnitFloorPlan.extraBedroom))}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Bathroom","content":((_vm.salesPurchase.projectUnitFloorPlan.numberOfBathroom) + " + " + (_vm.salesPurchase.projectUnitFloorPlan.extraBathroom))}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Tenure","content":_vm.salesPurchase.projectUnit.tenureType.length > 0
              ? _vm.salesPurchase.projectUnit.tenureType.join(', ')
              : 'No Tenure Type'}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Lot Type","content":_vm.$isStringEmpty(_vm.salesPurchase.projectUnit.lotType)
              ? 'No Lot Type'
              : _vm.salesPurchase.projectUnit.lotType.name}}),_c('display-data',{class:_vm.projectInfoDataCSS,attrs:{"label":"Address"}},[(_vm.salesPurchase.project.building)?_c('span',[_vm._v(" "+_vm._s(_vm.$toTitleCase(_vm.salesPurchase.project.building.name))+", ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$toTitleCase(_vm.salesPurchase.projectUnit.street))+", "+_vm._s(_vm.$toTitleCase(_vm.salesPurchase.project.area.name))+", "+_vm._s(_vm.$toTitleCase(_vm.salesPurchase.project.city.name))+", "+_vm._s(_vm.$toTitleCase(_vm.salesPurchase.project.state.name))+" ")])],1)]),_c('reservation-info',{staticClass:"bordered-container p-3 mb-2 mx-1",attrs:{"projectPurchase":_vm.salesPurchase}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }